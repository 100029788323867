import { FormEvent, useEffect, useState } from "react"
import styled from "styled-components"

import { FormControl, InputLabel, Select, TextField } from "@material-ui/core"

import { useFetchDeviceCount } from "src/data/devices/queries/deviceQueries"
import { HardwareType } from "src/data/devices/types/deviceTypes"
import { usePostVirtualDevice } from "src/data/homes/queries/virtualDeviceQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { brandText } from "src/ui/colors"
import { BodyMixin, Heading2Mixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { slugify } from "src/utils/genericUtil"

const DEVICE_NAME = "description"
const HARDWARE_TYPE = "hardwareType"
const SOUND_DURATION = "soundDuration"

interface AddVirtualDeviceFormData {
  [DEVICE_NAME]: { value: string }
  [HARDWARE_TYPE]: { value: HardwareType }
  [SOUND_DURATION]: { value: string }
}

export function AddVirtualDeviceForm({
  formId,
  homeId,
  afterSubmitSuccess,
}: {
  formId: string
  homeId: string
  afterSubmitSuccess: () => void
}) {
  const { orgId } = useOrganization()
  const postVirtualDevice = usePostVirtualDevice()
  const [hardwareType, setHardwareType] = useState(HardwareType.P3)
  const [name, setName] = useState<string>("")

  const fetchHome = useFetchHome({ orgId, homeId })
  const fetchDeviceCount = useFetchDeviceCount({
    orgId,
    filter: { home_ids: [homeId] },
  })

  // Set a useful default name, when fetches are done and when hardware type is changed.
  useEffect(() => {
    const homeName: string = fetchHome.data?.name ?? "home"
    const deviceCount: number = fetchDeviceCount.data ?? 0
    const defaultDeviceName = `${slugify(homeName)}:${hardwareType}-${deviceCount}`
    setName(defaultDeviceName)
  }, [fetchDeviceCount.data, fetchHome.data?.name, hardwareType])

  if (fetchHome.isLoading || fetchDeviceCount.isLoading) {
    return null
  }
  if (fetchHome.isError || fetchDeviceCount.isError) {
    return null
  }

  return (
    <FormBox
      id={formId}
      onSubmit={(e: FormEvent<HTMLFormElement & AddVirtualDeviceFormData>) => {
        e.preventDefault()

        const formData = e.currentTarget
        const soundDuration = parseInt(formData[SOUND_DURATION].value)

        postVirtualDevice.mutate(
          { orgId, homeId, description: name, hardwareType, soundDuration },
          { onSuccess: () => afterSubmitSuccess() }
        )
      }}
    >
      <Title>Add a virtual device</Title>
      <Description>
        This device will appear in your home as any real device would, and upon
        creation you'll get a control panel at the bottom of the screen that you
        can use to control the virtual environment around the device.
      </Description>

      <FormControl variant="outlined" fullWidth>
        <InputLabel
          htmlFor="Device generation"
          required
          style={{ background: "white" }}
        >
          Hardware type
        </InputLabel>
        <Select
          autoFocus
          required
          native
          name={HARDWARE_TYPE}
          inputProps={{
            id: "hardwareType",
          }}
          value={hardwareType}
          onChange={(e) => {
            const hardware = e.currentTarget.value
            setHardwareType(hardware as HardwareType)
          }}
        >
          <option value="" hidden />
          {Object.values(HardwareType)
            .filter((hw) => hw !== HardwareType.NONE && hw !== HardwareType.P1)
            .map((gen) => (
              <option value={gen} key={gen}>
                {getShortname(gen)}
              </option>
            ))}
        </Select>
      </FormControl>

      <TextField
        label="Device name"
        name={DEVICE_NAME}
        fullWidth
        value={name}
        onChange={(e) => setName(e.currentTarget.value)}
      />

      <TextField
        label="Sound duration in seconds"
        name={SOUND_DURATION}
        fullWidth
        type="number"
        inputProps={{
          min: 30,
          max: 900,
        }}
        defaultValue={30}
      />
    </FormBox>
  )
}

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
  grid-template-columns: 1fr;
  padding: ${spacing.XS2};
`

const Title = styled.div`
  margin-bottom: ${spacing.M};
  ${Heading2Mixin};
`

const Description = styled.div`
  margin-bottom: ${spacing.XL2};
  ${BodyMixin};
  color: ${brandText};
`

function getShortname(hw: HardwareType): string {
  switch (hw) {
    case HardwareType.A1:
      return "A1 (Eureka)"
    case HardwareType.P2:
      return "M2 (P2)"
    case HardwareType.P3:
      return "M3 (Amarillo)"
    default:
      return "Unknown device type"
  }
}
